<template>
  <div class="newsContent">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack()">资讯详情</div>
    <div class="card">
        <div class="newtitle">{{detail.newsTheme}}</div>
        <div class="newtime flex">
              <img src="@/assets/img/time.png">
              <span style="margin-right: 1vw">{{detail.publishTime}}</span>            
              <img src="@/assets/img/link.png">
              <span>{{detail.orgName ? detail.orgName : '桂信融'}}</span>            
        </div>
        <div class="card-html" v-html="replacePath(detail.newsContent)"></div>
       
        <div v-if="detail.attachmentName" class="accessory">附件：<span>{{detail.attachmentName}}</span></div>
    </div>

    
  </div>
</template>
<script>
// import TabBar from '@/components/TabBar.vue';
import { useRouter } from 'vue-router';
import { apiNewsDetail } from '@/api/axios';
import { Toast } from 'vant';
import { ref } from 'vue';
import { useStore } from 'vuex';
export default {
   setup() {
     let router = useRouter();
     let store = useStore();
     let id = router.currentRoute.value.params.id;
     const detail = ref({});
     apiNewsDetail({newsId: id}).then(res => {               
             if(res.code == '1'){
                 detail.value = res.info.newsDetail;
              }
              else {
                Toast(res.msg);
              }
          });
      const replacePath = (str) => {
            let reg = new RegExp('uploadFiles/',"g")
            let newstr=str.replace(reg,store.state.imgURL2+'uploadFiles/');
            return newstr;
        }
        const goBack = () => {
          router.push({ name:'news',params:{ type:0},replace:true}); 
        }
    return {
      store,
      detail,
      replacePath,
      goBack

     };
  },

}
</script>
<style lang="less" scoped>
.newsContent {
    padding-top: 12.5vw;
    .card {
        .newtitle {
            font-size: 5vw;
            font-weight: 500;
        }
        .newtime {
            font-size: 3.3vw;
            color: #ccc;
            padding: 4vw 0;
            img {
            width: 3.5vw;
            height: auto;
            margin-right: 1vw;
            }
        }
        .newtext {
            font-size: 4vw;
            margin: 4vw 0;
            line-height: 7vw;
        }
        img {
            width: 100%;
            height: auto;
        }
        .accessory {
          font-size: 4vw;
          word-break: break-all;
          span {
            color: rgb(19, 100, 250);
          }
        }
    }
}
</style>
